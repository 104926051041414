import { useMutation } from 'react-query';
import { Typography } from '@squantumengine/horizon';
import type { AxiosError } from 'axios';
import { useProfileStore } from 'common/stores/profile';
import { LogoutIcon, EditNewIcon, LockIcon } from 'common/components/icons';
import { goToLogout } from 'common/utils/auth';
import { generateErrorMessage } from 'common/utils/error-handler';
import { local } from 'common/utils/storage';
import ToastMessage from 'common/utils/toast-message';
import type { ErrorResponse } from 'common/types/global';
import { GENERAL_ERROR_MESSAGE } from 'common/constants/errors';
import { logout } from 'services/auth';
import { edit, updatePassword } from 'services/user';

const { Paragraph } = Typography;

const ProfilePopover = () => {
	const name = useProfileStore((state) => state.name);
	const email = useProfileStore((state) => state.email);

	const logoutMutation = useMutation(logout, {
		onSuccess: () => {
			goToLogout();
		},
		onError: () => {
			ToastMessage({
				type: 'error',
				label: 'Gagal keluar. Silahkan coba lagi.',
			});
		},
	});

	const editProfileMutation = useMutation(edit, {
		onSuccess: () => {
			window.location.href = `${process.env.REACT_APP_SQE_ID_EDIT_PROFILE_URL}?client_id=${process.env.REACT_APP_SQE_ID_CLIENT_ID}&redirect_uri=${window.location.href}&username=${email}&edited_username=${email}`;
		},
		onError: (error: AxiosError<ErrorResponse>) => {
			generateErrorMessage(error, GENERAL_ERROR_MESSAGE);
		},
	});

	const updatePasswordMutation = useMutation(updatePassword, {
		onSuccess: () => {
			window.location.href = `${process.env.REACT_APP_SQE_ID_UPDATE_PASSWORD_URL}?client_id=${process.env.REACT_APP_SQE_ID_CLIENT_ID}&redirect_uri=${window.location.href}&username=${email}`;
		},
		onError: (error: AxiosError<ErrorResponse>) => {
			generateErrorMessage(error, GENERAL_ERROR_MESSAGE);
		},
	});

	const editProfile = () => {
		editProfileMutation.mutate();
	};

	const editPassword = () => {
		updatePasswordMutation.mutate();
	};

	const handleLogout = () => {
		logoutMutation.mutate({
			refresh_token: local.getItem('refresh_token') as string,
		});
	};

	return (
		<div className="flex flex-col w-[260px]">
			{name && email ? (
				<div className="py-2 px-4 flex flex-col border-b border-stroke-secondary">
					<Paragraph weight="semibold" size="r">
						{name}
					</Paragraph>
					<Paragraph
						className="mt-1 text-secondary text-ellipsis overflow-hidden whitespace-nowrap"
						title={email}
						weight="regular"
						size="r"
					>
						{email}
					</Paragraph>
				</div>
			) : null}
			<div
				className="flex cursor-pointer items-center py-3 px-4 hover:bg-blue-50 border-b border-stroke-secondary"
				onClick={editProfile}
			>
				<div className="text-[24px] h-6 flex items-center justify-center">
					<EditNewIcon />
				</div>
				<Paragraph className="ml-2" weight="regular" size="r">
					Edit profil
				</Paragraph>
			</div>

			<div
				className="flex cursor-pointer items-center py-3 px-4 hover:bg-blue-50 border-b border-stroke-secondary"
				onClick={editPassword}
			>
				<div className="text-[24px] h-6 flex items-center justify-center">
					<LockIcon />
				</div>
				<Paragraph className="ml-2" weight="regular" size="r">
					Ubah password
				</Paragraph>
			</div>
			<div
				className="flex cursor-pointer items-center py-3 px-4 hover:bg-blue-50"
				onClick={handleLogout}
			>
				<LogoutIcon />
				<Paragraph className="ml-2" weight="regular" size="r">
					Keluar
				</Paragraph>
			</div>
		</div>
	);
};

export default ProfilePopover;
